<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  /deep/.el-select .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";
  import printCardHeader from "./printCard-header";
  import CjConditionSearch from "@/components/form/cjConditionSearch.vue";
  import {
    getZkzInfo,
    editZkz
  } from "@/api/printCard/printCard.js"
  import {
    getZkz,
    deleteZkz,
    userStatus
  } from "@/api/printCard/printCardInfo.js"

  /**
   * 准考证库检查
   */
  export default {
    components: {
      Layout,
      CheckHeader,
      printCardHeader,
      CjConditionSearch


    },
    data() {
      return {
        title: "准考证库导入 ",
        title2: "",
        items: [{
            text: "用户首页",
            href: '/admin'
          },
          {
            text: "控制面板",
            active: true
          }
        ],
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0,
          ksnf: "",
          zkzkid: "",
          keyName: "",
          keyWord: ""
        },
        sid: "",
        years: [],
        mbh: "",
        addForm: {},
        tableList: [],
        examList: [],
        sfyxcx: 0,
        conditionList: [{
            name: "民族",
            type: "select",
            val: "mzm",
            glzdb: "dict_mz"
          },
          {
            name: "证件号码",
            type: "input",
            val: "zjhm"
          },
          {
            name: "考生号",
            type: "input",
            val: "ksh"
          },
          {
            name: "性别",
            type: "radio",
            val: "xb",
            // glzdb: "dict_xb",
          },
          {
            name: "科目1成绩",
            type: "input",
            val: "cj1"
          },
          {
            name: "科目2成绩",
            type: "input",
            val: "cj2"
          },
          {
            name: "科目3成绩",
            type: "input",
            val: "cj3"
          },
          {
            name: "科目4成绩",
            type: "input",
            val: "cj4"
          },
          {
            name: "科目5成绩",
            type: "input",
            val: "cj5"
          },
          {
            name: "科目6成绩",
            type: "input",
            val: "cj6"
          },
          {
            name: "科目7成绩",
            type: "input",
            val: "cj7"
          },
          {
            name: "科目8成绩",
            type: "input",
            val: "cj8"
          },
          {
            name: "科目9成绩",
            type: "input",
            val: "cj9"
          },
        ],
        keyName: [{
            name: "姓名",
            val: "ksxm",
          },
          {
            name: "考生号",
            val: "ksh",
          },
          {
            name: "身份证号",
            val: "zjhm",
          }
        ]
      };
    },
    methods: {

      // 获取详情
      getDeatails(sid) {
        getZkzInfo(sid).then(res => {
          if (res.status) {
            this.addForm = res.data
            this.pageData.zkzkid = res.data.sid
            this.pageData.ksnf = res.data.ksnf
            this.mbh = res.data.mbh
            this.title2 = res.data.ksmc ? res.data.ksmc : ""
            this.getList()

          }
        })

      },
      // 准考证分页
      getList() {
        getZkz(this.pageData).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total
          }
        })
      },
      searchClick() { //搜索时先将分页的页码归1
        this.pageData.pageNum = 1; //当前页				
        this.getList() //渲染数据的函数
      },
      // 编辑准考证库
      editZkz() {
        this.sfyxcx = 1
        let formData = {}
        formData.sid = this.sid
        formData.sfyxcx = this.sfyxcx
        editZkz(formData).then(res => {
          console.log(res)
          if (res.status) {
            this.$message({
              type: "success",
              message: "准考证库发布成功！",
            });
          }
        })
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },
      // 删除数据
      deleteItem(obj) {
        this.$confirm(`此操作将永久删除该【${obj.ksxm}】的准考证数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteZkz(obj.sid).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据删除成功!'
              });
            }
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      //模板预览
      mouldPreview(item) {
        if (item.cscx) {
          let token = localStorage.getItem('accessToken')
          item.token = token
          item.zkzkid = this.sid
          item.zkzkmbh = this.mbh
          window.open(this.REGISTER_URL + "/ticketSearch?data=" + JSON.stringify(item), '_blank');
          return;
        }
        let routeUrl = this.$router.resolve({
          path: "/admin/printCardPreview",
          query: {
            sid: this.sid,
            userSfzh: item.sfzjhEnc,
            mbh: this.mbh
          }
        });
        if (this.mbh) {
          window.open(routeUrl.href, '_blank');
        } else {
          this.$message({
            type: "warning",
            message: "请先创建准考证样式！",
          });
        }
      },

      // 更多条件
      getListByCondition(e) {
        this.pageData = {
          ...this.pageData,
          ...e
        }
        this.$refs.cjConditionSearch.hide()
        this.getList()
      },
      // 改变启用状态
      changeQyzt(obj) {
        userStatus(obj.cscx, obj.sfzjhEnc, this.pageData.zkzkid).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: '状态修改成功！'
            })
          }
        })
      },
    },
    mounted() {
      if (this.sid) {
        this.getDeatails(this.sid)
      }
    },
    created() {
      this.sid = this.$route.query["sid"] ? this.$route.query["sid"] : "";

    }
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="title2" :items="items" />
    <printCardHeader ref="header" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList" style="flex: 1;">
                <el-select class=" mr-2 " style="width:8%;" clearable placeholder="请选择" v-model="pageData.keyName"
                  size="small">
                  <el-option v-for="(item, i) in keyName" :label="item.name" :value="item.val" :key="i">
                  </el-option>
                </el-select>
                <input placeholder="关键字" class="h30 w-10 form-control border-blue mr-2" maxlength="50"
                  v-model="pageData.keyWord" />
                <button type="button" class="btn btn-info h30 flexList mr-2" @click="searchClick"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
                <!-- <b-button variant="outline-info" class="flexList w-md mr-2 condition"
                  @click="$refs.cjConditionSearch.show(conditionList)"><i
                    class="iconfont icon-fasfa-chevron-circle-down mr-2"></i>更多条件</b-button> -->
              </div>

            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 3%;"> 序号</th>
                    <th style="width: 5%;">姓名</th>
                    <th style="width: 9%;">证件号</th>
                    <th style="width: 3%;">性别 </th>
                    <th style="width: 9%;">档案号 </th>
                    <th style="width: 6%;">准考证号 </th>
                    <th style="width: 5%;">考场号 </th>
                    <th style="width: 5%;">座位号</th>
                    <th style="width: 5%;">考点名称 </th>
                    <th style="width: 6%;">考点地址</th>
                    <th style="width: 8%;">考试日期</th>
                    <th style="width: 6%;">考试时间</th>
                    <th style="width: 8%;">招录机关</th>
                    <th style="width: 6%;">内设机构</th>
                    <th style="width:5%">测试状态</th>
                    <th style="width: 5%;">打印预览</th>
                    <th style="width: 4%;">操作 </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj,index) in tableList" :key="index">
                    <td>{{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                    <td><a href="" class="blue-font">{{obj.ksxm}}</a></td>
                    <td>{{obj.zjhm}}</td>
                    <td>
                      {{obj.xb}}
                    </td>
                    <td>{{obj.dah}}</td>
                    <td>{{obj.zkzh}}</td>
                    <td>{{obj.kch}}</td>
                    <td>{{obj.zwh}}</td>
                    <td>{{obj.kdmc}}</td>
                    <td>{{obj.kddz}}</td>
                    <td>{{obj.ksrq}}</td>
                    <td>{{obj.kssj}}</td>
                    <td>{{obj.zljg}}</td>
                    <td>{{obj.lsjg}}</td>
                    <td>
                      <b-form-checkbox v-model="obj.cscx" switch class=" switch-check" @change="changeQyzt(obj)">
                      </b-form-checkbox>
                    </td>
                    <td class="tab-icon"><i class="iconfont icon-graphic mr-1" @click="mouldPreview(obj)"></i></td>
                    <td class="tab-icon ">
                      <i class="iconfont icon-riLine-delete-bin-line" @click="deleteItem(obj)"></i>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>
            <div style="clear: both;height: 60px;">
              <!-- <button class="btn btn-info h35 mr-2 sub-btn" @click="editZkz">确认成绩库发布</button> -->
              <a href="javascript:history.back()"><button class="btn btn-secondary h35 mr-2 ">返回上一步</button></a>
            </div>
          </div>
        </div>
      </div>
    </div>


    </div>
    <!-- 弹窗开始 -->
    <!-- 更多条件 -->

    <!-- <CjConditionSearch ref="cjConditionSearch" @search="getListByCondition"></CjConditionSearch> -->

    <!-- 弹窗结束 -->
  </Layout>
</template>
